import { TYPES } from './types';
import { normalizeItem } from '../utils/formatter';

export const addItem = (item) => {
  const normalizedItem = normalizeItem(item);
  return {
    type: TYPES.ADD_ITEM,
    payload: normalizedItem,
  };
};

export const removeItem = (itemId) => {
  return {
    type: TYPES.REMOVE_ITEM,
    payload: itemId,
  };
};

export const clearCart = () => {
  return {
    type: TYPES.CLEAR_CART,
    payload: {},
  };
};

export const adjustQuantity = (itemId, quantity) => {
  return {
    type: TYPES.ADJUST_QUANTITY,
    payload: { itemId, quantity },
  };
}

export const increaseQuantityByOne = (itemId) => {
  return {
    type: TYPES.INCREASE_QUANTITY_BY_ONE,
    payload: itemId,
  };
};

export const decreaseQuantityByOne = (itemId) => {
  return {
    type: TYPES.DECREASE_QUANTITY_BY_ONE,
    payload: itemId,
  };
};
