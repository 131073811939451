import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { BannerText } from '../components/common';
import * as cartActions from '../store/actions';
import * as cartSelectors from '../store/selectors';
import SiteLayout from '../components/SiteLayout';
import { topNavItems } from '../utils/routes';
import { MIN_PROD_QUANTITY, MAX_PROD_QUANTITY, MAX_WIDTH } from '../utils/constants';
import { formatCurrency } from '../utils/formatter';
import { StyledLink } from '../utils/ui';

const EmptyCartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    padding: 18px 0px;
  }
`;

const EmptyCart = () => {
  return (
    <EmptyCartWrapper>
      <span>Giỏ hàng của bạn đang trống</span>
      <StyledLink className="product-button" to="/product">
        <buton className="uk-button uk-button-default">Xem sản phẩm</buton>
      </StyledLink>
    </EmptyCartWrapper>
  );
};

const CartItemWrapper = styled.tr`
  .cart-item-name,
  .cart-item-clear {
    cursor: pointer;
  }
  img {
    width: 150px;
    height: 150px;
    object-fit: contain;
  }
  input {
    width: 70px;
  }
  span {
    display: none;
    width: 120px;
  }
  @media screen and (max-width: ${MAX_WIDTH}) {
    span {
      display: inline-block;
    }
    td {
      padding: 0;
    }
  }
`;

const CartItem = ({ cartItem, adjustQuantity, removeItem }) => {
  const { id, name, unit_price, top_image_url, relative_url, quantity } = cartItem;
  const [tempQuantity, setTempQuantity] = useState(quantity);

  const onInputChange = (e) => {
    const newValue = e.target.value;
    if (newValue >= MIN_PROD_QUANTITY && newValue <= MAX_PROD_QUANTITY) {
      setTempQuantity(newValue);
    }
  };

  useEffect(() => {
    if (tempQuantity !== quantity) {
      adjustQuantity(id, tempQuantity);
    }
  }, [tempQuantity]);

  return (
    <CartItemWrapper>
      <td className="cart-item-image">
        <img src={top_image_url} alt={name} />
      </td>
      <td className="cart-item-name">
        <span>Tên sản phẩm: </span>
        <StyledLink to={relative_url}>{name}</StyledLink>
      </td>
      <td className="cart-item-quantity">
        <span>Số lượng: </span>
        <input className="uk-input uk-form-width-xsmall" type="number" value={tempQuantity} onChange={onInputChange} />
      </td>
      <td className="cart-item-currency">
        <span>Đơn giá: </span>
        {formatCurrency(unit_price)}
      </td>
      <td className="cart-item-clear">
        <button className="uk-button uk-button-default" onClick={() => removeItem(id)}>
          Xóa
        </button>
      </td>
    </CartItemWrapper>
  );
};

const CartWrapper = styled.div`
  max-width: ${MAX_WIDTH};
  width: 100%;
  box-sizing: border-box;
  margin: 80px 0px;
  .cart-title {
    font-family: Cormorant Infant;
    font-style: normal;
    font-weight: 600;
    font-size: 55px;
    line-height: 67px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000000;
  }
  .content-inner {
    padding: 26px;
  }
  h2 {
    text-align: center;
  }
  tr {
    padding: 16px 0px;
  }
  .summary-checkout {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media screen and (max-width: ${MAX_WIDTH}) {
    .summary-checkout {
      flex-direction: column;
      align-items: flex-start;
      button {
        margin-top: 16px;
      }
    }
  }
`;

const Cart = (props) => {
  const { cart, totalPrice, adjustQuantity, clearCart, removeItem } = props;
  const onClearAllCart = () => {
    clearCart();
  };
  return (
    <SiteLayout activeKey={topNavItems.find((item) => item.key === 'ABOUT_US')}>
      <CartWrapper>
        <p className="cart-title">Giỏ hàng</p>
        {cart?.length > 0 ? (
          <div className="content-inner">
            <table className="uk-table uk-table-hover uk-table-responsive uk-table-middle uk-table-divider">
              <thead>
                <tr>
                  <th>Sản phẩm</th>
                  <th>Tên</th>
                  <th>Số lượng</th>
                  <th>Giá tiền</th>
                  <th onClick={onClearAllCart}>Xoá tất cả</th>
                </tr>
              </thead>
              <tbody>
                {cart.map((cartItem) => (
                  <CartItem
                    key={cartItem?.id}
                    cartItem={cartItem}
                    adjustQuantity={adjustQuantity}
                    removeItem={removeItem}
                  />
                ))}
              </tbody>
            </table>
            <div className="summary-checkout">
              <span>
                Tổng tiền: <b>{formatCurrency(totalPrice)}</b>
              </span>
              <button className="uk-button uk-button-default">Thanh Toán</button>
            </div>
          </div>
        ) : (
            <EmptyCart />
          )}
      </CartWrapper>
    </SiteLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    totalPrice: cartSelectors.getTotalPrice(state),
    totalCartItems: cartSelectors.getTotalCartItems(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    adjustQuantity: (itemId, quantity) => dispatch(cartActions.adjustQuantity(itemId, quantity)),
    clearCart: () => dispatch(cartActions.clearCart()),
    removeItem: (itemId) => dispatch(cartActions.removeItem(itemId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
